import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section, Strong, Hr, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"pro-nas"} />
		<Helmet>
			<title>
				EduQuest
			</title>
			<meta name={"description"} content={"Ваш будиночок в горах із чанами для справжнього релаксу"} />
			<meta property={"og:title"} content={"EduQuest"} />
			<meta property={"og:description"} content={"Ваш будиночок в горах із чанами для справжнього релаксу"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section padding="16px 40px 16px 40px" quarkly-title="Header" position="absolute" sm-padding="16px 20px 16px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
				width="100%"
				max-width="none"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline2Lg">
					EduQuest
				</Text>
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" padding="0px 0px 0px 0px" background="none" />
					<Override
						slot="Button Text"
						md-text-transform="uppercase"
						md-color="--darkL2"
						md-letter-spacing="1px"
						md-font="--base"
					/>
					<Override slot="Button Icon" md-color="--darkL2" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link text-decoration-line="initial" color="--darkL2" font="--base" href="/index">
							Головна
						</Link>
						<Link
							md-margin="20px 0px 0px 0px"
							href="/pro-nas"
							text-decoration-line="initial"
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
						>
							Про нас
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="/poslugi"
							text-decoration-line="initial"
						>
							Послуги
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="/kontakti"
							text-decoration-line="initial"
						>
							Контакти
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" quarkly-title="HeroBlock" background="linear-gradient(0deg,rgba(249, 248, 243, 0.2) 0%,rgba(249, 248, 243, 0.6) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/262732078-1024x768.jpg?v=2024-11-26T14:27:26.265Z) center/cover repeat scroll padding-box" sm-padding="100px 20px 100px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="40px"
				max-width="none"
				width="100%"
				sm-flex-direction="column"
				min-height="80vh"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0px 0px 0"
				color="--darkL2"
				font="--headline1Lg"
				width="60%"
				lg-font="--headline1Lg"
				sm-font="--headline2Lg"
				sm-width="100%"
			>
				Ваш будиночок в горах із чанами для справжнього релаксу
			</Text>
			<Text
				sm-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0"
				color="--darkL2"
				sm-text-align="left"
				font="--headline2Lg"
				width="40%"
				text-align="right"
				lg-font="--headline3Lg"
				sm-width="100%"
			>
				ПРО НАС
			</Text>
		</Section>
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
			/>
			<Box padding="20px 20px 20px 20px" md-width="100%" width="33.333%">
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
					<Strong font="--headline2Lg">
						Чому варто обрати нас?
					</Strong>
					<br />
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline2Lg">
					Затишок і комфорт
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Усі наші кімнати обладнані для вашого максимального комфорту. Сучасна кухня, тепла дерев’яна атмосфера та зручні ліжка зроблять ваш відпочинок незабутнім.
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline2Lg" letter-spacing="-.06em" color="--dark">
					Чани
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Гарячі чани з карпатськими травами – це наш унікальний сервіс. Вони допоможуть зняти стрес, розслабити м’язи та покращити самопочуття.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" background="--color-lightD1">
			<Override slot="SectionContent" align-items="center" text-align="left" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="right"
			>
				"EduQuest24" – місце, де природа зустрічається з комфортом.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Ми створили це місце для тих, хто хоче відчути справжню красу Карпат і відновити внутрішню гармонію. Наш будиночок розташований у тихому місці, далеко від туристичних шумів, але водночас поруч із головними природними та культурними пам’ятками регіону.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				<Strong>
					Наша місія
				</Strong>
				{" "}– подарувати вам не лише відпочинок, а й відчуття єдності з природою. Кожна деталь у нашому будиночку продумана, щоб створити атмосферу спокою та затишку.
			</Text>
			<Text as="p" font="--headline3Lg" margin="20px 0 20px 0" text-align="center">
				Ми дбаємо про кожного гостя, пропонуючи персоналізовані послуги, щоб ваш відпочинок був саме таким, як ви мріяли.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/poslugi"
			>
				Послуги
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});